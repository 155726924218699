<template>
  <b-modal
    visible
    scrollable
    size="s"
    centered
    :title="title"
    no-close-on-backdrop
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="description">
      Вы уверены, что хотите удалить подготовку к анализу?
    </div>

    <div class="crm-wrapper-buttons">
      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        class="ml-2"
        @click="onClose"
      >
        Отмена
      </b-button>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="delete-btn"
        @click="onDelete"
      >
        Да
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { laboratoryService } from '@/services';
import { showSuccessCustomMessage } from '@/helpers/messages';

export default {
  name: 'PreparingAnalysisDeleteModal',
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Удаление подготовки к анализу',
    },
    preparationId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async onDelete() {
      this.isLoading = true;

      try {
        await laboratoryService.deletePreparation(this.preparationId);
        showSuccessCustomMessage('Подготовка к анализам успешно удалена!');
        await this.$emit('afterDelete');
        this.onClose();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-wrapper-buttons{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
  .delete-btn{
    width: 100px;
  }
}

.description {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
